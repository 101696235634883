<template>
  <div class="Causes">
    <!-- POPULAR CAUSES SECTION -->
    <section class="causes">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery4.jpg" alt="cause">
              <div class="box">
                <h3>حالات الإعاقة الدائمة</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2000</h5>
                  <h5>تحقق: 1460</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery5.jpg" alt="cause">
              <div class="box">
                <h3>أطفال سرطان الكبد</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 3400</h5>
                  <h5>تحقق: 1745</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery6.jpg" alt="cause">
              <div class="box">
                <h3>حالات القلب المفتوح</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2500</h5>
                  <h5>تحقق: 2245</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery4.jpg" alt="cause">
              <div class="box">
                <h3>حالات الإعاقة الدائمة</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2000</h5>
                  <h5>تحقق: 1460</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery5.jpg" alt="cause">
              <div class="box">
                <h3>أطفال سرطان الكبد</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 3400</h5>
                  <h5>تحقق: 1745</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery6.jpg" alt="cause">
              <div class="box">
                <h3>حالات القلب المفتوح</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2500</h5>
                  <h5>تحقق: 2245</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery4.jpg" alt="cause">
              <div class="box">
                <h3>حالات الإعاقة الدائمة</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2000</h5>
                  <h5>تحقق: 1460</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery5.jpg" alt="cause">
              <div class="box">
                <h3>أطفال سرطان الكبد</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 3400</h5>
                  <h5>تحقق: 1745</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery6.jpg" alt="cause">
              <div class="box">
                <h3>حالات القلب المفتوح</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2500</h5>
                  <h5>تحقق: 2245</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- DONATION SECTION -->
    <section class="Donation">
      <div class="container">
        <h1>تبرع الان</h1>
        <div class="donate">
          <input type="number" placeholder="5">
          <span class="dollar">$</span>
        </div>
        <button>تبرع الان</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "جــود هـيـلـث | الحالات"
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.commit("changePage", {
      name: "الحالات",
      alt: true,
      engName: this.$router.history.current.name
    });
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("stopLoading");
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.Causes {
  // POPULAR CAUSES SECTION
  .causes {
    margin: 100px 0;
    .cause {
      box-shadow: 0 0px 7px rgba($color: #000000, $alpha: 0.25);
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: 300px;
        border-bottom: 5px solid $txtColor;
        @include sm {
          height: 250px;
        }
      }
      .box {
        padding: 15px 0 0px;
        h3 {
          margin-bottom: 15px;
          color: $txtColor;
          font-weight: bold;
          padding: 0 20px;
        }
        p {
          padding: 0 20px;
          margin: 0 0 15px;
        }
        .donation {
          h5 {
            position: relative;
            top: 5px;
            color: #fff;
            display: inline-block;
            width: 50%;
            padding: 10px;
            background: rgba($color: $txtColor, $alpha: 0.8);
            border: 3px double $txtColor;
            border-left: 0;
            border-right: 0;
            text-align: center;
            &:nth-of-type(2) {
              background: rgba($color: $txtColor, $alpha: 0.7);
            }
          }
        }
      }
      button {
        display: block;
        width: 100%;
        background: $txtColor;
        padding: 15px;
        color: #fff;
        font-weight: bold;
        border: 3px double $txtColor;
        border-top: 0;
        border-radius: 0 0 15px 15px;
        outline: none;
        &:hover {
          background: $mainColor;
          color: $txtColor;
          font-weight: bold;
        }
      }
    }
  }
  // DONATION SECTION
  .Donation {
    margin-bottom: 100px;
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 210px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -19px auto 50px;
        @include md {
          height: 11px;
          width: 170px;
          margin: -16px auto 50px;
        }
        @include sm {
          height: 10px;
          width: 150px;
          margin: -14px auto 50px;
        }
        @include xs {
          height: 10px;
          width: 140px;
          margin: -14px auto 40px;
        }
        @include xxs {
          height: 8px;
          width: 130px;
          margin: -10px auto 40px;
        }
      }
    }
    .donate {
      display: flex;
      text-align: center;
      justify-content: center;
      input {
        @extend %form-element;
        width: 200px;
        text-align: left;
        font-size: 24px;
        padding: 5px 15px;
        border-radius: 0 10px 10px 0;
        border: 2px solid $txtColor;
        border-left: 0;
        color: $txtColor;
        font-weight: bold;
        &:focus {
          border: 2px solid $txtColor;
          border-left: 0;
        }
        box-shadow: 5px 5px 5px rgba($color: $txtColor, $alpha: 0.5);
        font-family: "Kufam", cursive;
        margin: 0;
      }
      span {
        font-family: "Kufam", cursive;
        border-radius: 10px 0 0 10px;
        border: 2px solid $txtColor;
        padding: 5px 15px;
        font-size: 24px;
        color: $txtColor;
        font-weight: bold;
        box-shadow: -5px 5px 5px rgba($color: $txtColor, $alpha: 0.5);
      }
    }
    button {
      display: block;
      margin: 25px auto;
      padding: 8px 30px;
      border-radius: 6px;
      border: 2px solid $txtColor;
      font-weight: bold;
      background: $txtColor;
      color: $mainColor;
      outline: none;
      &:hover {
        background: #fff;
        color: $txtColor;
      }
    }
  }
}
</style>
