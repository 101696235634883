<template>
  <div class="Events">
    <!-- EVENTS SECTION -->
    <section class="events">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event1.jpg" alt="event-image">
                <span>17 سبتمبر</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    5:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الثقافة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event2.jpg" alt="event-image">
                <span>22 مارس</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    7:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الجامعة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event1.jpg" alt="event-image">
                <span>17 سبتمبر</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    5:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الثقافة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event2.jpg" alt="event-image">
                <span>22 مارس</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    7:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الجامعة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event1.jpg" alt="event-image">
                <span>17 سبتمبر</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    5:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الثقافة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event2.jpg" alt="event-image">
                <span>22 مارس</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    7:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الجامعة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "جــود هـيـلـث | الاحداث"
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.commit("changePage", {
      name: "الاحداث",
      alt: true,
      engName: this.$router.history.current.name
    });
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("stopLoading");
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.Events {
  // EVENTS SECTION
  .events {
    margin: 120px 0;
    .event {
      box-shadow: 0px 7px 7px rgba($color: $txtColor, $alpha: 0.2);
      border-radius: 15px;
      overflow: hidden;
      margin-bottom: 35px;
      .img {
        position: relative;
        img {
          height: 400px;
          @include sm {
            height: 350px;
          }
          @include xs {
            height: 300px;
          }
        }
        span {
          position: absolute;
          right: 15px;
          bottom: 15px;
          display: inline-block;
          background: $txtColor;
          font-weight: bold;
          padding: 10px 30px;
          color: #fff;
          border-radius: 5px;
          font-size: 24px;
        }
      }
      .details {
        .timeNplace {
          display: flex;
          justify-content: center;
          .time {
            flex: 0 0 50%;
            border-left: 1px solid #ccc;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ccc;
            i {
              margin-left: 5px;
            }
            &:hover {
              background: $txtColor;
              color: #fff;
              border-bottom: 1px solid $mainColor;
            }
          }
          .place {
            flex: 0 0 50%;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ccc;
            i {
              margin-left: 5px;
            }
            &:hover {
              background: $txtColor;
              color: #fff;
              border-bottom: 1px solid $mainColor;
            }
          }
        }
        p {
          padding: 10px 20px 20px;
        }
        button {
          display: block;
          width: 100%;
          background: $txtColor;
          padding: 15px;
          color: #fff;
          font-weight: bold;
          border: 0;
          border-top: 0;
          border-radius: 0 0 15px 15px;
          &:hover {
            background: $mainColor;
            color: $txtColor;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
