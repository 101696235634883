<template>
  <div class="About">
    <!-- ABOUT SECTION -->
    <section class="about">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="img">
              <img src="@imgs/about.jpg" alt="about-us-image">
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="about-us">
              <h4>من نحن!</h4>
              <h1>جـود هـيـلـث</h1>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
                إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص
              </p>
              <button>تبرع الان</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- WATCH US SECTION -->
    <section class="watch">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="discription">
            <h2>شاهد أخر نشاطاتنا!</h2>
            <p>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
              إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص
            </p>
            <p>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
              إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص
            </p>
            <button>تبرع الان</button>
          </div>
        </div>
        <div class="col-md-6 col-12 vidImage">
          <div class="overlay"></div>
          <img src="@imgs/gallery1.jpg" alt="vidImage">
          <div class="circle" title="شاهد الفيديو التعريفي">
            <div class="triangle"></div>
          </div>
        </div>
      </div>
    </section>
    <!-- NUMBERS SECTION -->
    <section class="numbers">
      <div class="container">
        <div class="numbers-cont">
          <div class="decoration1"></div>
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon1.svg" alt="icon-image">
                <h2>450</h2>
                <p>حالات متعافية</p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon2.svg" alt="icon-image">
                <h2>25</h2>
                <p>أحداث تمت</p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon3.svg" alt="icon-image">
                <h2>85</h2>
                <p>زيارات ميدانية</p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon4.svg" alt="icon-image" class="editimage">
                <h2>1700</h2>
                <p>تبرعات</p>
              </div>
            </div>
          </div>
          <div class="decoration2"></div>
        </div>
      </div>
    </section>
    <!-- TEAM SECTION -->
    <section class="team">
      <div class="container">
        <h1>فريق العمل</h1>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <div class="member">
              <img src="@imgs/team1.jpg" alt="memeber">
              <div class="box">
                <h3>ياسمين السيد</h3>
                <p>مدرس بجامعة القاهرة</p>
              </div>
              <hr>
              <div class="social-icons">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="member">
              <img src="@imgs/team2.jpg" alt="memeber">
              <div class="box">
                <h3>عبدالله محمد جمال</h3>
                <p>مدرس بجامعة القاهرة</p>
              </div>
              <hr>
              <div class="social-icons">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="member">
              <img src="@imgs/team3.jpg" alt="memeber">
              <div class="box">
                <h3>أحمد محمد إبراهيم</h3>
                <p>مدرس بجامعة القاهرة</p>
              </div>
              <hr>
              <div class="social-icons">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- DONATION SECTION -->
    <section class="donation">
      <div class="container">
        <h1>تبرع الان</h1>
        <div class="donate">
          <input type="number" placeholder="5">
          <span class="dollar">$</span>
        </div>
        <button>تبرع الان</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "about",
  metaInfo: {
    title: "جــود هـيـلـث | عن الموقع"
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.commit("changePage", {
      name: "عن الموقع",
      alt: true,
      engName: this.$router.history.current.name
    });
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("stopLoading");
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.About {
  // ABOUT US SECTION
  .about {
    padding: 130px 0 80px;
    @include xs {
      padding: 80px 0;
    }
    .img {
      border: 10px solid $txtColor;
      background: $mainColor;
      border-bottom: 0;
      border-right: 0;
      img {
        position: relative;
        left: 15px;
        top: 12px;
      }
      @include xs {
        border: 5px solid $txtColor;
        border-bottom: 0;
        border-right: 0;
        img {
          position: relative;
          left: 5px;
          top: 5px;
        }
      }
    }
    .about-us {
      @include sm {
        margin-top: 30px;
      }
      h4 {
        color: $txtColor;
        @include xs {
          text-align: center;
        }
      }
      h1 {
        font-family: "Kufam", cursive;
        color: $txtColor;
        font-weight: bold;
        margin-bottom: 20px;
        @include xs {
          margin-top: 10px;
          text-align: center;
        }
        &::after {
          content: "";
          display: block;
          width: 300px;
          height: 13px;
          background: rgba($color: $mainColor, $alpha: 0.85);
          margin: -20px 0 30px;
          @include md {
            height: 11px;
            width: 230px;
            margin: -16px 0 30px;
          }
          @include sm {
            height: 10px;
            width: 200px;
            margin: -14px 0 30px;
          }
          @include xs {
            height: 10px;
            width: 180px;
            margin: -14px auto 30px;
          }
          @include xxs {
            height: 8px;
            width: 165px;
            margin: -11px auto 20px;
          }
        }
      }
      p {
        line-height: 30px;
      }
      button {
        background: $txtColor;
        border: 2px solid $txtColor;
        margin: 15px 0 0;
        padding: 8px 30px;
        color: #fff;
        &:hover {
          border-color: $mainColor;
        }
      }
    }
  }
  // WATCH VIDEO SECTION
  .watch {
    margin: 100px 0;
    background: rgba($color: $mainColor, $alpha: 0.2);
    @include xs {
      margin: 20px 0 50px;
    }
    .discription {
      padding: 60px;
      width: 100%;
      height: 100%;
      h2 {
        color: $txtColor;
        font-weight: bold;
        font-family: "Kufam", cursive;
        @include xs {
          text-align: center;
        }
        &::after {
          content: "";
          display: block;
          width: 370px;
          height: 13px;
          background: rgba($color: $mainColor, $alpha: 0.85);
          margin: -20px 0 30px;
          @include md {
            height: 11px;
            width: 300px;
            margin: -16px 0 30px;
          }
          @include sm {
            height: 10px;
            width: 260px;
            margin: -14px 0 30px;
          }
          @include xs {
            height: 10px;
            width: 220px;
            margin: -11px auto 30px;
          }
          @include xxs {
            height: 8px;
            width: 210px;
            margin: -9px auto 20px;
          }
        }
      }
      p {
        line-height: 35px;
        @include xs {
          line-height: 25px;
        }
        &:nth-of-type(2) {
          @include lg {
            display: none;
          }
        }
      }
      button {
        background: $txtColor;
        border: 2px solid $txtColor;
        margin: 15px 0 0;
        padding: 8px 30px;
        color: #fff;
        &:hover {
          border-color: $mainColor;
        }
        @include xs {
          display: block;
          margin: 15px auto 0;
        }
      }
    }
    .vidImage {
      position: relative;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: $mainColor, $alpha: 0.4);
      }
      img {
        width: 100%;
        height: 100%;
      }
      .circle {
        border-radius: 50%;
        background: rgba($color: $txtColor, $alpha: 0.5);
        @include center(true);
        width: 130px;
        height: 130px;
        border: 5px double $mainColor;
        cursor: pointer;
        @include xs {
          width: 80px;
          height: 80px;
        }
        &:hover {
          background: rgba($color: $txtColor, $alpha: 0.8);
        }
        .triangle {
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 35px solid $mainColor;
          @include center(true);
          width: 0;
          height: 0;
          top: 50%;
          left: 55%;
          @include xs {
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 25px solid $mainColor;
          }
        }
      }
    }
  }
  // NUMBER SECTION
  .numbers {
    margin-top: 150px;
    margin-bottom: 100px;
    .numbers-cont {
      padding: 40px 25px;
      background: $txtColor;
      text-align: center;
      position: relative;
      border-radius: 25px 0 25px 0;
      .number {
        @include sm {
          margin-bottom: 30px;
        }
        @include xs {
          margin-bottom: 40px;
        }
        img {
          width: 70px;
          height: 70px;
          margin-bottom: 15px;
          @include sm {
            margin-bottom: 5px;
          }
          &.editimage {
            width: 75px;
            height: 75px;
            position: relative;
            top: 10px;
          }
        }
        h2 {
          color: $secColor;
          font-weight: bold;
          font-family: "Kufam", cursive !important;
        }
        p {
          color: $mainColor;
          font-weight: bold;
          font-size: 20px;
        }
      }
      .decoration1 {
        background: url("../assets/images/paper.png");
        width: 100%;
        height: 15px;
        position: absolute;
        top: -8px;
        z-index: 999;
      }
      .decoration2 {
        background: url("../assets/images/paper.png");
        width: 100%;
        height: 15px;
        position: absolute;
        bottom: -8px;
        z-index: 999;
        transform: rotate(180deg);
      }
    }
  }
  // TEAM SECTION
  .team {
    margin: 200px 0;
    @include xs {
      margin: 100px 0;
    }
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 280px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -20px auto 70px;
        @include md {
          height: 11px;
          width: 210px;
          margin: -16px auto 70px;
        }
        @include sm {
          height: 10px;
          width: 190px;
          margin: -14px auto 70px;
        }
        @include xs {
          height: 10px;
          width: 170px;
          margin: -14px auto 60px;
        }
        @include xxs {
          height: 8px;
          width: 150px;
          margin: -10px auto 60px;
        }
      }
    }
    .member {
      box-shadow: 2px 10px 4px rgba($color: $txtColor, $alpha: 0.1);
      overflow: hidden;
      border-radius: 15px;
      text-align: center;
      @include sm {
        margin-bottom: 20px;
      }
      &:hover {
        transform: translateY(-5px);
        box-shadow: 2px 20px 5px rgba($color: $txtColor, $alpha: 0.2);
      }
      img {
        width: 100%;
        height: 300px;
        border-bottom: 5px solid $txtColor;
        @include sm {
          height: 250px;
        }
      }
      .box {
        padding: 10px 0 0px;
        h3 {
          margin-bottom: 5px;
          color: $txtColor;
          font-weight: bold;
          padding: 0 20px;
        }
        p {
          padding: 0 20px;
        }
      }
      .social-icons {
        padding: 0px 15px 15px;
        @extend %social-icons;
        i {
          &:first-of-type {
            padding: 5px 9px;
            color: $facebook;
            border-color: $facebook;
            &:hover {
              background: $facebook;
              color: #fff;
            }
          }
          &:nth-of-type(2) {
            padding: 6px;
            color: $twitter;
            border-color: $twitter;
            &:hover {
              background: $twitter;
              color: #fff;
            }
          }
          &:nth-of-type(3) {
            padding: 6px 7px;
            color: $linkedin;
            border-color: $linkedin;
            &:hover {
              background: $linkedin;
              color: #fff;
            }
          }
          background: none;
        }
      }
    }
  }
  // DONATION SECTION
  .donation {
    margin-bottom: 100px;
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 210px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -19px auto 50px;
        @include md {
          height: 11px;
          width: 170px;
          margin: -16px auto 50px;
        }
        @include sm {
          height: 10px;
          width: 150px;
          margin: -14px auto 50px;
        }
        @include xs {
          height: 10px;
          width: 140px;
          margin: -14px auto 40px;
        }
        @include xxs {
          height: 8px;
          width: 130px;
          margin: -10px auto 40px;
        }
      }
    }
    .donate {
      display: flex;
      text-align: center;
      justify-content: center;
      input {
        @extend %form-element;
        width: 200px;
        text-align: left;
        font-size: 24px;
        padding: 5px 15px;
        border-radius: 0 10px 10px 0;
        border: 2px solid $txtColor;
        border-left: 0;
        color: $txtColor;
        font-weight: bold;
        &:focus {
          border: 2px solid $txtColor;
          border-left: 0;
        }
        box-shadow: 5px 5px 5px rgba($color: $txtColor, $alpha: 0.5);
        font-family: "Kufam", cursive;
        margin: 0;
      }
      span {
        font-family: "Kufam", cursive;
        border-radius: 10px 0 0 10px;
        border: 2px solid $txtColor;
        padding: 5px 15px;
        font-size: 24px;
        color: $txtColor;
        font-weight: bold;
        box-shadow: -5px 5px 5px rgba($color: $txtColor, $alpha: 0.5);
      }
    }
    button {
      display: block;
      margin: 25px auto;
      padding: 8px 30px;
      border-radius: 6px;
      border: 2px solid $txtColor;
      font-weight: bold;
      background: $txtColor;
      color: $mainColor;
      outline: none;
      &:hover {
        background: #fff;
        color: $txtColor;
      }
    }
  }
}
</style>
