<template>
  <div class="home">
    <!-- TOP THREE BOXES -->
    <section class="three-boxs">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12 boxCont">
            <div class="box">
              <h2>شارك الان</h2>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
                إذا كنت تحتاج إلى عدد أكبر من الفقرات
              </p>
              <button>اقرأ المزيد</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12 boxCont">
            <div class="box">
              <h2>تطوع الان</h2>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
                إذا كنت تحتاج إلى عدد أكبر من الفقرات
              </p>
              <button>اقرأ المزيد</button>
            </div>
          </div>
          <div class="col-md-4 col-12 boxCont">
            <div class="box">
              <h2>تبرع الان</h2>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
                إذا كنت تحتاج إلى عدد أكبر من الفقرات
              </p>
              <button>اقرأ المزيد</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ABOUT SECTION -->
    <section class="about">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="img">
              <img src="@imgs/about.jpg" alt="about-us-image">
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="about-us">
              <h4>من نحن!</h4>
              <h1>جـود هـيـلـث</h1>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
                إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص
              </p>
              <button>تبرع الان</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- WATCH US SECTION -->
    <section class="watch">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="discription">
            <h2>شاهد أخر نشاطاتنا!</h2>
            <p>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
              إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص
            </p>
            <p>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
              إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص
            </p>
            <button>تبرع الان</button>
          </div>
        </div>
        <div class="col-md-6 col-12 vidImage">
          <div class="overlay"></div>
          <img src="@imgs/gallery1.jpg" alt="vidImage">
          <div class="circle" title="شاهد الفيديو التعريفي">
            <div class="triangle"></div>
          </div>
        </div>
      </div>
    </section>
    <!-- POPULAR CAUSES SECTION -->
    <section class="causes">
      <div class="container">
        <h1>أشهر الحالات</h1>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery4.jpg" alt="cause">
              <div class="box">
                <h3>حالات الإعاقة الدائمة</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2000</h5>
                  <h5>تحقق: 1460</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery5.jpg" alt="cause">
              <div class="box">
                <h3>أطفال سرطان الكبد</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 3400</h5>
                  <h5>تحقق: 1745</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="cause">
              <img src="@imgs/gallery6.jpg" alt="cause">
              <div class="box">
                <h3>حالات القلب المفتوح</h3>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <div class="donation">
                  <h5>الهدف: 2500</h5>
                  <h5>تحقق: 2245</h5>
                </div>
              </div>
              <button @click="$router.push(`/causes/1rLRxoybOI_4s`)">تبرع الان</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- NUMBERS SECTION -->
    <section class="numbers">
      <div class="container">
        <div class="numbers-cont">
          <div class="decoration1"></div>
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon1.svg" alt="icon-image">
                <h2>450</h2>
                <p>حالات متعافية</p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon2.svg" alt="icon-image">
                <h2>25</h2>
                <p>أحداث تمت</p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon3.svg" alt="icon-image">
                <h2>85</h2>
                <p>زيارات ميدانية</p>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="number">
                <img src="@imgs/icon4.svg" alt="icon-image" class="editimage">
                <h2>1700</h2>
                <p>تبرعات</p>
              </div>
            </div>
          </div>
          <div class="decoration2"></div>
        </div>
      </div>
    </section>
    <!-- TEAM SECTION -->
    <section class="team">
      <div class="container">
        <h1>فريق العمل</h1>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12">
            <div class="member">
              <img src="@imgs/team1.jpg" alt="memeber">
              <div class="box">
                <h3>ياسمين السيد</h3>
                <p>مدرس بجامعة القاهرة</p>
              </div>
              <hr>
              <div class="social-icons">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-pinterest-p"></i>
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="member">
              <img src="@imgs/team2.jpg" alt="memeber">
              <div class="box">
                <h3>عبدالله محمد جمال</h3>
                <p>مدرس بجامعة القاهرة</p>
              </div>
              <hr>
              <div class="social-icons">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-pinterest-p"></i>
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="member">
              <img src="@imgs/team3.jpg" alt="memeber">
              <div class="box">
                <h3>أحمد محمد إبراهيم</h3>
                <p>مدرس بجامعة القاهرة</p>
              </div>
              <hr>
              <div class="social-icons">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-pinterest-p"></i>
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- EVENTS SECTION -->
    <section class="events">
      <div class="container">
        <h1>أخر الاحداث</h1>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event1.jpg" alt="event-image">
                <span>17 سبتمبر</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    5:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الثقافة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="event">
              <div class="img">
                <img src="@imgs/event2.jpg" alt="event-image">
                <span>22 مارس</span>
              </div>
              <div class="details">
                <div class="timeNplace">
                  <span class="time">
                    <i class="far fa-clock"></i>
                    7:00 م
                  </span>
                  <span class="place">
                    <i class="fas fa-map-marker-alt"></i>
                    سوهاج - الجامعة
                  </span>
                </div>
                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.</p>
                <button @click="$router.push('/events/lb4XqfOCnW')">أحجز تذكرة حضور</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- PARTNERS SECTION -->
    <div class="partners">
      <div class="decoration1"></div>
      <div class="overlay">
        <div class="container">
          <h1>الداعمون</h1>
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="partner">
                <img src="@imgs/partner1.png" alt="partner">
                <h3>جود هيلث</h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="partner">
                <img src="@imgs/partner3.png" alt="partner">
                <h3>جود هيلث</h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="partner">
                <img src="@imgs/partner1.png" alt="partner">
                <h3>جود هيلث</h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="partner">
                <img src="@imgs/partner3.png" alt="partner">
                <h3>جود هيلث</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="decoration2"></div>
    </div>
    <!-- DONATION SECTION -->
    <section class="Donation">
      <div class="container">
        <h1>تبرع الان</h1>
        <div class="donate">
          <input type="number" placeholder="5">
          <span class="dollar">$</span>
        </div>
        <button>تبرع الان</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  metaInfo: {
    title: "جــود هـيـلـث | الرئيسية"
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.commit("changePage", {
      name: "الرئيسية",
      alt: false,
      engName: this.$router.history.current.name
    });
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("stopLoading");
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.home {
  // THREE TOP BOXES
  .three-boxs {
    position: relative;
    top: -200px;
    z-index: 999;
    @include xs {
      top: 0;
    }
    .boxCont {
      background: $secColor;
      padding: 10px;
      box-shadow: 0 4px 7px rgba($color: #000000, $alpha: 0.4);
      margin-bottom: 10px;
      &:nth-of-type(2) {
        background: $mainColor;
        position: relative;
        top: -35px;
        transform: scale(1.1);
        @include sm {
          top: -24px;
        }
        @include xs {
          top: 0;
          transform: scale(1);
        }
      }
      @include xs {
        box-shadow: none;
        background: transparent !important;
      }
      .box {
        padding: 25px;
        border: 2px dashed $txtColor;
        @include xs {
          box-shadow: 0 4px 7px rgba($color: #000000, $alpha: 0.4);
          background: $secColor;
        }
        h2 {
          color: $txtColor;
          font-weight: bold;
          @include xs {
            text-align: center;
          }
          &::after {
            content: "";
            display: block;
            width: 100px;
            height: 3px;
            background: $txtColor;
            margin: 20px 0;
            @include xs {
              margin: 10px auto;
            }
          }
        }
        p {
          color: rgb(90, 90, 90);
        }
        button {
          background: transparent;
          border: 2px solid $txtColor;
          margin: 15px 0 0;
          padding: 8px 30px;
          color: $txtColor;
          @include xs {
            display: block;
            margin: 15px auto 0;
          }
          &:hover {
            background: $txtColor;
            color: #fff;
            border-color: $mainColor;
          }
        }
      }
      &:hover {
        background: $txtColor;
        .box {
          border: 2px solid $mainColor;
          h2 {
            color: $mainColor;
            &::after {
              background: $mainColor;
            }
          }
          button {
            border: 2px solid $mainColor;
            color: #fff;
            color: $mainColor;
            &:hover {
              background: $mainColor;
              color: $txtColor;
              border-color: $txtColor;
            }
          }
          p {
            color: #aaa;
          }
        }
      }
    }
  }
  // ABOUT US SECTION
  .about {
    padding: 0 0 80px;
    @include xs {
      padding: 80px 0;
    }
    .img {
      border: 10px solid $txtColor;
      background: $mainColor;
      border-bottom: 0;
      border-right: 0;
      img {
        position: relative;
        left: 15px;
        top: 12px;
      }
      @include xs {
        border: 5px solid $txtColor;
        border-bottom: 0;
        border-right: 0;
        img {
          position: relative;
          left: 5px;
          top: 5px;
        }
      }
    }
    .about-us {
      @include sm {
        margin-top: 30px;
      }
      h4 {
        color: $txtColor;
        @include xs {
          text-align: center;
        }
      }
      h1 {
        font-family: "Kufam", cursive;
        color: $txtColor;
        font-weight: bold;
        margin-bottom: 20px;
        @include xs {
          margin-top: 10px;
          text-align: center;
        }
        &::after {
          content: "";
          display: block;
          width: 300px;
          height: 13px;
          background: rgba($color: $mainColor, $alpha: 0.85);
          margin: -20px 0 30px;
          @include md {
            height: 11px;
            width: 230px;
            margin: -16px 0 30px;
          }
          @include sm {
            height: 10px;
            width: 200px;
            margin: -14px 0 30px;
          }
          @include xs {
            height: 10px;
            width: 180px;
            margin: -14px auto 30px;
          }
          @include xxs {
            height: 8px;
            width: 165px;
            margin: -11px auto 20px;
          }
        }
      }
      p {
        line-height: 30px;
      }
      button {
        background: $txtColor;
        border: 2px solid $txtColor;
        margin: 15px 0 0;
        padding: 8px 30px;
        color: #fff;
        &:hover {
          border-color: $mainColor;
        }
      }
    }
  }
  // WATCH VIDEO SECTION
  .watch {
    margin: 100px 0;
    background: rgba($color: $mainColor, $alpha: 0.2);
    @include xs {
      margin: 20px 0 50px;
    }
    .discription {
      padding: 60px;
      width: 100%;
      height: 100%;
      h2 {
        color: $txtColor;
        font-weight: bold;
        font-family: "Kufam", cursive;
        @include xs {
          text-align: center;
        }
        &::after {
          content: "";
          display: block;
          width: 370px;
          height: 13px;
          background: rgba($color: $mainColor, $alpha: 0.85);
          margin: -20px 0 30px;
          @include md {
            height: 11px;
            width: 300px;
            margin: -16px 0 30px;
          }
          @include sm {
            height: 10px;
            width: 260px;
            margin: -14px 0 30px;
          }
          @include xs {
            height: 10px;
            width: 220px;
            margin: -11px auto 30px;
          }
          @include xxs {
            height: 8px;
            width: 210px;
            margin: -9px auto 20px;
          }
        }
      }
      p {
        line-height: 35px;
        @include xs {
          line-height: 25px;
        }
        &:nth-of-type(2) {
          @include lg {
            display: none;
          }
        }
      }
      button {
        background: $txtColor;
        border: 2px solid $txtColor;
        margin: 15px 0 0;
        padding: 8px 30px;
        color: #fff;
        &:hover {
          border-color: $mainColor;
        }
        @include xs {
          display: block;
          margin: 15px auto 0;
        }
      }
    }
    .vidImage {
      position: relative;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: $mainColor, $alpha: 0.4);
      }
      img {
        width: 100%;
        height: 100%;
      }
      .circle {
        border-radius: 50%;
        background: rgba($color: $txtColor, $alpha: 0.5);
        @include center(true);
        width: 130px;
        height: 130px;
        border: 5px double $mainColor;
        cursor: pointer;
        @include xs {
          width: 80px;
          height: 80px;
        }
        &:hover {
          background: rgba($color: $txtColor, $alpha: 0.8);
        }
        .triangle {
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 35px solid $mainColor;
          @include center(true);
          width: 0;
          height: 0;
          top: 50%;
          left: 55%;
          @include xs {
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 25px solid $mainColor;
          }
        }
      }
    }
  }
  // POPULAR CAUSES SECTION
  .causes {
    margin: 100px 0;
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 320px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -22px auto 70px;
        @include md {
          height: 11px;
          width: 250px;
          margin: -16px auto 70px;
        }
        @include sm {
          height: 10px;
          width: 200px;
          margin: -14px auto 70px;
        }
        @include xs {
          height: 10px;
          width: 180px;
          margin: -14px auto 20px;
        }
        @include xxs {
          height: 8px;
          width: 170px;
          margin: -11px auto 50px;
        }
      }
    }
    .cause {
      box-shadow: 0 0px 7px rgba($color: #000000, $alpha: 0.25);
      overflow: hidden;
      border-radius: 10px;
      @include sm {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        height: 300px;
        border-bottom: 5px solid $txtColor;
        @include sm {
          height: 250px;
        }
      }
      .box {
        padding: 15px 0 0px;
        h3 {
          margin-bottom: 15px;
          color: $txtColor;
          font-weight: bold;
          padding: 0 20px;
        }
        p {
          padding: 0 20px;
          margin: 0 0 15px;
        }
        .donation {
          h5 {
            position: relative;
            top: 5px;
            color: #fff;
            display: inline-block;
            width: 50%;
            padding: 10px;
            background: rgba($color: $txtColor, $alpha: 0.8);
            border: 3px double $txtColor;
            border-left: 0;
            border-right: 0;
            text-align: center;
            &:nth-of-type(2) {
              background: rgba($color: $txtColor, $alpha: 0.7);
            }
          }
        }
      }
      button {
        display: block;
        width: 100%;
        background: $txtColor;
        padding: 15px;
        color: #fff;
        font-weight: bold;
        border: 3px double $txtColor;
        border-top: 0;
        border-radius: 0 0 15px 15px;
        outline: none;
        &:hover {
          background: $mainColor;
          color: $txtColor;
          font-weight: bold;
        }
      }
    }
  }
  // NUMBER SECTION
  .numbers {
    margin-bottom: 100px;
    .numbers-cont {
      padding: 40px 25px;
      background: $txtColor;
      text-align: center;
      position: relative;
      border-radius: 25px 0 25px 0;
      .number {
        @include sm {
          margin-bottom: 30px;
        }
        @include xs {
          margin-bottom: 40px;
        }
        img {
          width: 70px;
          height: 70px;
          margin-bottom: 15px;
          @include sm {
            margin-bottom: 5px;
          }
          &.editimage {
            width: 75px;
            height: 75px;
            position: relative;
            top: 10px;
          }
        }
        h2 {
          color: $secColor;
          font-weight: bold;
          font-family: "Kufam", cursive !important;
        }
        p {
          color: $mainColor;
          font-weight: bold;
          font-size: 20px;
        }
      }
      .decoration1 {
        background: url("../assets/images/paper.png");
        width: 100%;
        height: 15px;
        position: absolute;
        top: -8px;
        z-index: 999;
      }
      .decoration2 {
        background: url("../assets/images/paper.png");
        width: 100%;
        height: 15px;
        position: absolute;
        bottom: -8px;
        z-index: 999;
        transform: rotate(180deg);
      }
    }
  }
  // TEAM SECTION
  .team {
    margin: 200px 0;
    @include xs {
      margin: 100px 0;
    }
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 280px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -20px auto 70px;
        @include md {
          height: 11px;
          width: 210px;
          margin: -16px auto 70px;
        }
        @include sm {
          height: 10px;
          width: 190px;
          margin: -14px auto 70px;
        }
        @include xs {
          height: 10px;
          width: 170px;
          margin: -14px auto 60px;
        }
        @include xxs {
          height: 8px;
          width: 150px;
          margin: -10px auto 60px;
        }
      }
    }
    .member {
      box-shadow: 2px 10px 4px rgba($color: $txtColor, $alpha: 0.1);
      overflow: hidden;
      border-radius: 15px;
      text-align: center;
      @include sm {
        margin-bottom: 20px;
      }
      &:hover {
        transform: translateY(-5px);
        box-shadow: 2px 20px 5px rgba($color: $txtColor, $alpha: 0.2);
      }
      img {
        width: 100%;
        height: 300px;
        border-bottom: 5px solid $txtColor;
        @include sm {
          height: 250px;
        }
      }
      .box {
        padding: 10px 0 0px;
        h3 {
          margin-bottom: 5px;
          color: $txtColor;
          font-weight: bold;
          padding: 0 20px;
        }
        p {
          padding: 0 20px;
        }
      }
      .social-icons {
        padding: 0px 15px 15px;
        @extend %social-icons;

        i {
          color: $txtColor;
          border-color: $txtColor;
          background: none;
          transition: all 0.3s ease-in-out;
          &:first-of-type {
            padding: 5px 9px;
          }
          &:nth-of-type(2) {
            padding: 6px;
          }
          &:nth-of-type(3) {
            padding: 6px 8px;
          }
          &:nth-of-type(4) {
            padding: 6px 7px;
          }
          &:hover {
            color: #fff;
            &:first-of-type {
              background: $facebook;
              border-color: $facebook;
            }
            &:nth-of-type(2) {
              background: $twitter;
              border-color: $twitter;
            }
            &:nth-of-type(3) {
              background: $pinterest;
              border-color: $pinterest;
            }
            &:nth-of-type(4) {
              background: $linkedin;
              border-color: $linkedin;
            }
          }
        }
      }
    }
  }
  // EVENTS SECTION
  .events {
    margin: 120px 0;
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 280px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -20px auto 70px;
        @include md {
          height: 11px;
          width: 210px;
          margin: -16px auto 70px;
        }
        @include sm {
          height: 10px;
          width: 190px;
          margin: -14px auto 70px;
        }
        @include xs {
          height: 10px;
          width: 170px;
          margin: -14px auto 60px;
        }
        @include xxs {
          height: 8px;
          width: 160px;
          margin: -10px auto 60px;
        }
      }
    }
    .event {
      box-shadow: 0px 7px 7px rgba($color: $txtColor, $alpha: 0.1);
      border-radius: 15px;
      overflow: hidden;
      @include sm {
        margin-bottom: 25px;
      }
      .img {
        position: relative;
        img {
          height: 400px;
          @include sm {
            height: 350px;
          }
          @include xs {
            height: 300px;
          }
        }
        span {
          position: absolute;
          right: 15px;
          bottom: 15px;
          display: inline-block;
          background: $txtColor;
          font-weight: bold;
          padding: 10px 30px;
          color: #fff;
          border-radius: 5px;
          font-size: 24px;
        }
      }
      .details {
        .timeNplace {
          display: flex;
          justify-content: center;
          .time {
            flex: 0 0 50%;
            border-left: 1px solid #ccc;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ccc;
            i {
              margin-left: 5px;
            }
            &:hover {
              background: $txtColor;
              color: #fff;
              border-bottom: 1px solid $mainColor;
            }
          }
          .place {
            flex: 0 0 50%;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ccc;
            i {
              margin-left: 5px;
            }
            &:hover {
              background: $txtColor;
              color: #fff;
              border-bottom: 1px solid $mainColor;
            }
          }
        }
        p {
          padding: 10px 20px 20px;
        }
        button {
          display: block;
          width: 100%;
          background: $txtColor;
          padding: 15px;
          color: #fff;
          font-weight: bold;
          border: 0;
          border-top: 0;
          border-radius: 0 0 15px 15px;
          &:hover {
            background: $mainColor;
            color: $txtColor;
            font-weight: bold;
          }
        }
      }
    }
  }
  // PARTNERS SECTION
  .partners {
    margin-top: 200px;
    margin-bottom: 100px;
    background: url("../assets/images/partners.jpg") fixed no-repeat;
    background-size: cover;
    position: relative;
    .overlay {
      padding: 80px 0 100px;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.7);
      @include xs {
        padding: 70px 0 100px;
      }
    }
    h1 {
      font-family: "Kufam", cursive !important;
      color: $mainColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 220px;
        height: 13px;
        background: rgba($color: $txtColor, $alpha: 0.85);
        margin: -20px auto 70px;
        @include md {
          height: 11px;
          width: 170px;
          margin: -16px auto 70px;
        }
        @include sm {
          height: 10px;
          width: 150px;
          margin: -14px auto 70px;
        }
        @include xs {
          height: 10px;
          width: 140px;
          margin: -14px auto 50px;
        }
        @include xxs {
          height: 8px;
          width: 130px;
          margin: -10px auto 50px;
        }
      }
    }
    .partner {
      @include sm {
        margin-bottom: 20px;
      }
      text-align: center;
      img {
        width: 100px;
        height: 100px;
      }
      h3 {
        color: #fff;
        font-weight: bold;
      }
    }
    .decoration1 {
      background: url("../assets/images/paper.png");
      width: 100%;
      height: 15px;
      position: absolute;
      top: -8px;
      z-index: 999;
    }
    .decoration2 {
      background: url("../assets/images/paper.png");
      width: 100%;
      height: 15px;
      position: absolute;
      bottom: -8px;
      z-index: 999;
      transform: rotate(180deg);
    }
  }
  // DONATION SECTION
  .Donation {
    margin-bottom: 100px;
    h1 {
      font-family: "Kufam", cursive !important;
      color: $txtColor;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include xs {
        margin-top: 10px;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        width: 210px;
        height: 13px;
        background: rgba($color: $mainColor, $alpha: 0.85);
        margin: -19px auto 50px;
        @include md {
          height: 11px;
          width: 170px;
          margin: -16px auto 50px;
        }
        @include sm {
          height: 10px;
          width: 150px;
          margin: -14px auto 50px;
        }
        @include xs {
          height: 10px;
          width: 140px;
          margin: -14px auto 40px;
        }
        @include xxs {
          height: 8px;
          width: 130px;
          margin: -10px auto 40px;
        }
      }
    }
    .donate {
      display: flex;
      text-align: center;
      justify-content: center;
      input {
        @extend %form-element;
        width: 200px;
        text-align: left;
        font-size: 24px;
        padding: 5px 15px;
        border-radius: 0 10px 10px 0;
        border: 2px solid $txtColor;
        border-left: 0;
        color: $txtColor;
        font-weight: bold;
        &:focus {
          border: 2px solid $txtColor;
          border-left: 0;
        }
        box-shadow: 5px 5px 5px rgba($color: $txtColor, $alpha: 0.5);
        font-family: "Kufam", cursive;
        margin: 0;
      }
      span {
        font-family: "Kufam", cursive;
        border-radius: 10px 0 0 10px;
        border: 2px solid $txtColor;
        padding: 5px 15px;
        font-size: 24px;
        color: $txtColor;
        font-weight: bold;
        box-shadow: -5px 5px 5px rgba($color: $txtColor, $alpha: 0.5);
      }
    }
    button {
      display: block;
      margin: 25px auto;
      padding: 8px 30px;
      border-radius: 6px;
      border: 2px solid $txtColor;
      font-weight: bold;
      background: $txtColor;
      color: $mainColor;
      outline: none;
      &:hover {
        background: #fff;
        color: $txtColor;
      }
    }
  }
}
</style>

